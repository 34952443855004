import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll & Wishlist guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_reroll.jpg"
            alt="Reroll guide"
          />
        </div>
        <div className="page-details">
          <h1>Reroll & Wishlist guide</h1>
          <h2>
            Guide detailing Reroll Process, its targets and what to select in
            Wishlist Banner in Eversoul!
          </h2>
          <p>
            Last updated: <strong>21/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Is it worth to reroll?" />
        <p>
          While normally rerolling in idle games is not worth the effort due to
          dupes playing an important part in character strength, Eversoul makes
          it worth it by having certain characters work extremely well even with
          only one copy.
        </p>
        <p>
          Make sure to login as Guest - this reroll guide works only when you
          use that option (when you're on the home screen, the guest option is
          available in the top-left corner of the screen).
        </p>
        <p>
          Rerolling in Eversoul can be divided into two parts - Infinite Banner
          Reroll and subsequent Rate-up Summon Reroll.
        </p>
        <SectionHeader title="Freebies" />
        <p>
          Eversoul is a pretty generous game, and during the first few days you
          will obtain a lot of Epics for free. Here's the list of the freebies:
        </p>
        <h5>Story Clear/Various Missions (Introduced on Game Launch)</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="clara" enablePopover />
          <EversoulCharacter mode="icon" slug="rebecca" enablePopover />
          <EversoulCharacter mode="icon" slug="seeha" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Linzy</strong> - Tutorial + Chapter 6 Clear Rewards. (2
            Copies Total)
          </li>
          <li>
            <strong>Talia</strong> - Given after you spend at least 1$ in the
            in-game shop,
          </li>
          <li>
            <strong>Chloe</strong> - Reward for clearing Chapter 2,
          </li>
          <li>
            <strong>Clara</strong> - Reward for clearing Chapter 3,
          </li>
          <li>
            <strong>Rebecca</strong> - Reward for clearing Chapter 5,
          </li>
          <li>
            <strong>Seeha</strong> - Reward from Boot Camp missions,
          </li>
          <li>
            <strong>Mephistopeles</strong> - Login calendar (day 7) + Prelude
            Episode 6 Clear.
          </li>
        </ul>
        <p>
          In Japanese Version of Eversoul, Boot Camp Missions reward has been
          changed to <strong>Petra</strong>.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
        </div>
        <h5>
          Grow Up Missions (Introduced on 1.5 Year Anniversary - July 4th, 2024)
        </h5>
        <p>
          Clear various Grow Up Missions aimed at beginners to unlock some
          really good rewards!
        </p>
        <ul>
          <li>
            <strong>Mephistopheles</strong> - Mephi is here once again, but this
            time you directly get an Origin Copy of her! (Equivalent to 8
            copies) from Main Story Chapter 3 clear mission.
          </li>
          <li>
            <strong>4x 2023 Epic Soul Selection Chest</strong> - Very Easy to do
            4 Missions that grant you 4 selectors overall that feature all souls
            excluding Angel/Demon released in 2023 from{' '}
            <strong>Mephistopheles to Otoha</strong>.
          </li>
          <li>
            <strong>
              1x Epic Soul Summon Ticket for Beast/Human/Fairy/Undead Souls.
            </strong>
          </li>
          <li>
            <strong>2x Legendary+ Rare Soul Selector</strong> - Easy fodder to
            instantly push an Epic Soul from Legendary+ to Eternal+ of your
            choice.
          </li>
        </ul>
        <h5>New Savior Attendance Pass (Introduced on November 21st, 2024)</h5>
        <p>
          This attendance grants a whopping 20 free souls including 4
          Angel/Demon Characters to all players for free (2023 Souls in release
          order). Furthermore, players can purchase Gold Pass for 7$ for an
          additional 1 copy each (for a total of 40 souls!)
        </p>
        <p>This includes</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="claudia" enablePopover />
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Angel/Demon Souls</strong> - Lizelotte (Day 6), Claudia (Day
            10), Yuria (Day 16), Eve (Day 20).
          </li>
          <li>
            <strong>Notable Regular Souls</strong> - Jiho (Day 1), Honglan (Day
            3), Aki (Day 5), Garnet (Day 7), Naomi (Day 11), Daphne (Day 12),
            Otoha (Day 19).
          </li>
          <li>
            <strong>Other Regular Souls</strong> - Velanna (Day 2), Erika (Day
            4), Manon (Day 8), Xiaolian (Day 9), Melfice (Day 13), Bryce (Day
            14), Lute (Day 15), Eileen (Day 17), Edith (Day 18).
          </li>
        </ul>
        <p>
          Just keep in mind that you need 8 dupes (14 dupes for Angel/Demon
          factions) in total to ascend a character to origin (the highest
          rarity) - so even if you pull a dupe, it isn't the end of the world.
        </p>
        <SectionHeader title="Infinite banner" />
        <p>
          Infinite banner is unlocked after you finish the tutorial, and the way
          it works is you 10 pull the banner and it gives you 1 Epic (excluding
          Angel/Demon characters), and you can continue pulling this banner
          until you get a character of your choice and then select it. Earlier
          this banner used to be simple roll till you get Daphne and start
          playing, however with Daphne being added to Savior Attendance Pass and
          also being available in 2023 Epic Soul Selection Chests, she is no
          longer the prime candidate.
        </p>
        <p>Instead we have now the choice of following Souls.</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="wheri" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
          <EversoulCharacter mode="icon" slug="onyx" enablePopover />
          <EversoulCharacter mode="icon" slug="dominique" enablePopover />
          <EversoulCharacter mode="icon" slug="lilith" enablePopover />
        </div>
        <h5>Healers</h5>
        <p>
          Although New Savior attendance grants out free Yuria, she is available
          after quite a few days. If you feel Jiho and Clara are unsatisfactory
          for your needs. You have the following 3 choices in the Healer
          department: Wheri = Beleth &gt; Onyx but all are very close in
          performance, so feel free to pick according to your personal
          preferences.
        </p>
        <ul>
          <li>
            <strong>Wheri</strong> - The Great Sage of Fayren Forest, is the
            newest Healer addition to the game. She is completely survival
            oriented with her kit providing some very strong heals or shields
            alongside other buffs based on her mode. Although she lacks much in
            the Offensive buffs department besides a singular Attack Buff.
          </li>
          <li>
            <strong>Beleth</strong> - Operator Five has been long hailed as
            second coming of Yuria with her great heals and plethora of
            Offensive buffs, unlike Wheri she is primarily an Offensive buffer,
            however her frequent jumps to frontline tend to get her killed
            sometimes.
          </li>
          <li>
            <strong>Onyx</strong> - Originally a strong contender to be the
            no-brainer for Infinite Reroll spot, Onyx takes a hit with New
            Savior Attendance providing players easy access to two most used A/D
            supports - Yuria and Eve, as Onyx has half her kit disabled when
            played with A/D/C characters. However, she still is a great addition
            for Team 2/3 in most PVE/Guild Raid content.
          </li>
        </ul>
        <h5>Buffer/Debuffers</h5>
        <p>
          If you feel the free Jiho/Clara is enough for your progression until
          you unlock Yuria, you can instead invest in Buffer/Debuffers for
          higher damage ceilings and faster content clears, in that case your
          choices are either Dominique or Lilith, both are great and can be
          chosen freely according to your preference.
        </p>
        <ul>
          <li>
            <strong>Dominique</strong> - The Queen of Troyca has been an
            excellent addition to the roster for any type of content since her
            release. An all-rounder soul that can function as a very strong
            Debuffer and Buffer and additionally as a Main DPS depending on her
            investment.
          </li>
          <li>
            <strong>Lilith</strong> - A recent addition to the roster, Lilith is
            a very strong Debuffer and increases your team's damage considerably
            in various Guild Raids and similar content, but most importantly for
            newbies, Lilith’s kit features additional buffs for Mephistopheles,
            an Origin unit you get for free, turning her into a very good Team 2
            Carry.
          </li>
        </ul>
        <p>
          After Infinite Banner is done, you can start playing the game normally
          and this marks the end of the Quick Reroll process, but if you are
          planning to put in a little more effort to get the best possible start
          – you’ll want to aim to get the most out of Rate-Up Summon next up
          additionally.
        </p>
        <p>
          In the Japanese Version of Eversoul, initially players can reroll for
          one of the following characters - Vivienne, Talia, Aki, Honglan (as
          and when they are added) until Daphne is released.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
        </div>
        <SectionHeader title="Rate-Up Summon" />
        <p>
          Once you’ve unlocked the menus, you can click on the three lines icon
          in the top right of the screen and open up the Hall of Fame. This will
          give you a crazy amount of summoning currency that is always claimable
          on a new account - everyone gets to benefit from the progress of
          others! (Roughly 150+ pulls at the time of writing this guide) and
          claiming other free pulls from ongoing events and logins.
        </p>
        <p>Now you have further two choices with the Everstones claimed:</p>
        <ul>
          <li>Pushing to 2-1 to Unlock Pick-Up Choice Summon.</li>
          <li>Rerolling in the current Rate-up Banner.</li>
        </ul>
        <h5>Pick-Up Choice Summon</h5>
        <StaticImage
          src="../../../images/eversoul/generic/reroll2.webp"
          alt="Guide"
        />
        <p>Unlocks after completing Battlefront 2-1. </p>
        <p>
          A new feature that was added with the half anniversary, the Choice
          banner is meant to help new players catch up by giving them a
          selection banner to pull on at the start of the game. While this
          banner features 17 characters you can choose between, there are only
          few real choices new players should make:
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="otoha" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Aki</strong> is one of the best Hyper Carries (aka scale
            extremely well with investment) in the game and as such is what we
            recommend everyone to choose from this banner. Aki is ST DPS which
            excels in one-shotting characters and she can do it very fast due to
            Mana Refund she gets on killing characters – allowing her to kill 3
            characters with Main-Ultimate-Main combo in seconds. Aki also ages
            extremely well into endgame and is usable in the majority of guild
            raid content early on.
          </li>
          <li>
            <strong>Otoha</strong> is similarly a great ST DPS choice if you are
            not fond of Aki or she is having a rerun. Her instant death
            mechanics below 3% HP allows her to get rid of Daphne with ease
            along other leeches that survive at few hundred HP and then have
            your next Main/Ultimate skill wasted on them. She is also usable in
            PVP, however will find next to no use in most Guild Raids and
            similar content.
          </li>
          <li>
            <strong>Honglan</strong> is another good choice after Aki and is
            comparable to Otoha, she is a classic AoE DPS which means she lacks
            the burst capability so your team would need overall well-rounded
            investment as she whittles them down. But her unmatched speed
            debuffs and mana reduction capabilities allow your team to always
            skill first and makes winning much easier. She also scales well in
            endgame but she lacks usage in guild raids compared to Aki.
          </li>
        </ul>
        <p>
          The goal here is to use all your Everstones to get Aki (or any other
          choice) to Origin in 300 mileage. You won’t be able to do this right
          away, however you should have enough to do about 120~180 pulls right
          at the start. If you can manage about 4 copies of Aki in those pulls,
          you should be set.
        </p>
        <h5>Current Rate-up Banner</h5>
        <p>
          Alternative if you don’t want to choose Aki or someone stronger/rarer
          is having a rate-up banner. This should be normally only chosen when
          Angel/Demon or Chaotic characters are having their initial banner or
          banner reruns, especially Chaotic characters as they can be only
          gotten from their respective rate-up banners or Erika Alchemy, a much
          costlier alternative.
        </p>
        <p>
          The aim in this case is similar to Pickup Summon above, with aiming to
          Origin character in 300 pulls (if non A/D/C) and at least securing 4-5
          pulled copies of A/D/C characters in 300 pulls for guaranteed Eternal
          + ascension with easy path to Origin in further pulls.
        </p>
        <p>
          Some re-run targets for this can be -{' '}
          <strong>
            Yuria, Eve, Lizelotte, Claudia, Linzy (Thanatos), Catherine
            (Radiance), Hazel, Dominique and Larimar
          </strong>{' '}
          (albeit Larimar is a Raid focused unit).
        </p>
        <h5>Summary</h5>
        <ul>
          <li>Get one of the 5 options from Infinite Reroll.</li>
          <li>
            Claim Everstones from Hall of Fame and any other ongoing
            Event/Missions along with Tickets/Shards etc.
          </li>
          <ul>
            <li>
              A) Check if any{' '}
              <strong>Angel / Demon / Chaotic Soul Banner</strong> is being
              run/rerun (or anything you prefer over Aki).
            </li>
            <li>
              B) If A is not valid, <strong>Push Battlefront until 2-1</strong>{' '}
              and unlock Pick-Up Choice Summon
            </li>
          </ul>
          <li>
            Use all Everstones on the Rate-up Banner of your choice from above
            and aim to Origin the soul/get 3-5 copies in whatever amount of
            summons you get.
          </li>
        </ul>
        <p>Congrats, the reroll aspect is now over!</p>
        <p>
          If you don’t get the results you wanted, this would be the time to
          start the reroll. Go to the Settings menu, and click Withdraw from the
          bottom of the Account menu. You should now be able to relog in as a
          guest and try again!
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/reroll1.webp"
          alt="Guide"
        />
        <p>
          If you’re satisfied with your account, your goals over the next 2
          weeks are as follows:
        </p>
        <ul>
          <li>
            Do <strong>Grow Up Missions</strong> and get free Origin Mephi and
            from first 2023 Epic Soul Selector - Pick Daphne. Save the other 3
            selectors to break the Origin/200 wall later on.
          </li>
          <li>Finish the 300 mileage on the banner you chose above.</li>
          <li>
            Use Tickets, Shards as acquired to expand the roster and get some
            lucky dupes.
          </li>
          <li>
            Set up <strong>Wishlist according to our Guide below</strong>
          </li>
          <li>Push Battlefront to 8-35 asap to unlock Tower of Origins.</li>
          <li>
            Clear as many as Tower of Origins, Gates and other content for some
            massive resource gains.
          </li>
          <li>And most importantly Have fun!</li>
        </ul>
        <SectionHeader title="Wishlist Summon" />
        <StaticImage
          src="../../../images/eversoul/generic/reroll3.webp"
          alt="Guide"
        />
        <p>
          On November 21st, 2024, Game introduced Wishlist Summon in the game,
          also referred to as Select Summon and Targeted Summon.
        </p>
        <p>
          This banner semi replaces the Traditional Type Summon banner, (they
          will co-exist, but a large part of monthly Type Summon Gain will be
          instead turned into Targeted Summon Tickets) and is intended to help
          New Players and Old alike by selecting a set of souls from the ever
          increasing pool to get only them.
        </p>
        <h5>How it works:</h5>
        <ul>
          <li>You can choose required Souls in 7 slots.</li>
          <ul>
            <li>
              <strong>5 Epic slots</strong>: You can choose 5 Souls from Human /
              Beast / Fairy / Undead type Souls.{' '}
              <strong>(0.77% Chance per Epic Slot)</strong>.
            </li>
            <li>
              <strong>1 Special slot</strong>: You can choose 1 Soul from Angel
              / Demon type Souls. <strong>(0.15% chance)</strong>.
            </li>
            <li>
              <strong>1 Rare Slot</strong>: You can choose 1 Soul from Rare
              Souls to focus on or leave it empty to get them randomly from the
              pool <strong>(46% Chance)</strong>.
            </li>
          </ul>
          <li>
            Only “Targeted Summon Ticket” is allowed in the Targeted Summon.
            (Everstone / Type Summon Ticket is not allowed).
          </li>
          <li>
            New Souls are added in the Targeted Summon 6 weeks after the
            release. (3 weeks after the Pick-Up Summon is ended)
          </li>
          <li>
            The <strong>30th summon is guaranteed as an Epic Soul</strong> if
            you didn’t obtain any Epic Soul for 30 pulls similarly to the Normal
            Summon banner, and likewise, if any soul is obtained earlier, the
            pity is reset at that point.
          </li>
        </ul>
        <h5>Where to Get?</h5>
        <ul>
          <li>Hall of Memories weekly rewards.</li>
          <li>New Savior / Returning / Normal Attendance Check</li>
          <li>Gachapon (Event Raid Shop)</li>
        </ul>
        <SectionHeader title="What to Wishlist?" />
        <p>
          There are a lot of Souls in the Sea, if you are wondering which one to
          wish for, we are here to help!
        </p>
        <h5>Special Slot</h5>
        <p>
          First let’s start with the <strong>Singular Special Slot</strong>{' '}
          which allows for Angel or Demon characters to be gained at{' '}
          <strong>0.15% chance</strong>.
        </p>
        <p>
          We have 3 mainstay choices here, either of them is a good pick
          depending on what you prefer.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="larimar" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Yuria</strong> - The Queen of Solrey is not only unmatched
            in Lore, but also in-game. Since her addition to the game in Q3
            2023, she has proven herself to be an unmatched support encompassing
            Healing, Offensive and Defensive buffs, Shields, Cleanse amongst
            others. Yuria is always useful regardless of content and regardless
            of what stage you are in the game - Newbie or Endgame. She also
            works very well at low investment.
          </li>
          <li>
            <strong>Eve</strong> - The Mother of all Souls, Eve has been the
            best Hyper Carry enabler since her addition on 1st Anniversary. Eve
            gives a plethora of Offensive and Defensive buffs to your Primary
            Carry (Highest attack based), that can significantly raise their
            performance and allow them to hit above their pay grade, beyond what
            normally the situation would allow for. She also works great at low
            investment and is generally a part of majority in-game content
            including competitive raids.
          </li>
          <li>
            <strong>Larimar</strong> - The latest Demon Soul released in Q3
            2024, who completely broke Guild Raid and similar competitive raid
            content meta to pieces with how overpowered her kit is. Larimar is a
            debuffer soul which multiplies your overall team damage by multiple
            manifolds with her damage accumulation and burst mechanics. Larimar
            can also hold her own with low investment, however, she is primarily
            a Guild Raid Soul, which depends on built Hyper Carries to do her
            thing, thus won't be as useful as Yuria/Eve in PvE or in initial
            stages of the game.
          </li>
        </ul>
        <p>
          You can choose to either invest in a singular unit, or use it to raise
          them all in a balanced way. Such as getting Yuria to Legendary+,
          swapping her for Eve and raising her, then Larimar, and then swapping
          back to Yuria to Eternal+ her and so on. The choice is yours!
        </p>
        <p>
          DPS Souls like Lizelotte/Claudia are not worth picking as they require
          Eternal+ - Origin investment to even get started, however if you are
          missing a copy or two, not a bad choice to gamble.
        </p>
        <h5>Epic Slots</h5>
        <p>
          Now let us come to the next part - <strong>5 Epic Slots</strong>,
          these can house any Beast/Fairy/Human/Undead soul of your choice and
          has a <strong>0.77% chance per soul (total 3.85%)</strong>.
        </p>
        <p>
          Accordingly, this will have much more variety due to the large pool,
          let us discuss some in our opinion that are best bang for money.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="hazel" enablePopover />
          <EversoulCharacter mode="icon" slug="sigrid" enablePopover />
          <EversoulCharacter mode="icon" slug="tasha" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Hazel</strong> - The Crown Princess of Tabria, Hazel is a
            Human Soul and perhaps the most rounded off DPS in the current
            roster even when accounting for A/D/C units after her recent
            November 2024 buffs. Having a Shock based kit, she primarily engages
            in fast and high damage gameplay, just like a Lightning Goddess of
            War. She excels in both 1v5 and 5v5 content and as such can find a
            place in almost every content in-game and perform extremely well. A
            prime contender for your primary DPS investment.
          </li>
          <li>
            <strong>Sigrid</strong> - The Duchess of Tabria and Queen of
            Underworld, Sigrid is an Undead Soul released recently and is not
            only a very strong DPS herself, but also a very good enabler for
            Dual DPS/Sub-DPS teams, with her whopping 60% Crit Damage increase
            on enemies with her Ultimate, which majority of Raid DPS and Dex
            characters absolutely love. Sigrid will find a place easily in most
            Raid oriented content and can be your Undead choice of Carry for
            Undead Depths/Gate. She is however a bit squishy for a frontline
            character in general PvE, so keeping her alive is an issue
            sometimes.
          </li>
          <li>
            <strong>Dominique</strong> - As mentioned earlier in Infinite Reroll
            Targets, The Queen of Troyca, Dominique is a Fairy Soul and has been
            an excellent addition to the roster for any type of content since
            her release. An all-rounder soul that can function as a very strong
            Debuffer and Buffer and additionally as a Main DPS depending on her
            investment be it PvE content or Competitive Raid content.
          </li>
          <li>
            <strong>Lilith</strong> - Similarly, another unit from Infinite
            Reroll Targets, Operator Nine, Lilith is a Human Soul and is a very
            strong Debuffer and increases your team's damage considerably in
            various Guild Raids and similar content, but most importantly for
            newbies, Lilith kit features additional buffs for Mephistopheles, an
            Origin unit you get for free, turning her into a very good Team 2
            Carry and Beleth, her fellow operator.
          </li>
          <li>
            <strong>Wheri</strong> - Yet another one from Reroll Targets, The
            Great Sage of Fayren Forest, Wheri is a Fairy Soul and is the newest
            Healer addition to the game, She is completely survival oriented
            with her kit providing some very strong heals or shields alongside
            other buffs based on her mode. Although she lacks much in the
            Offensive buffs department besides a singular Attack Buff.
          </li>
          <li>
            <strong>Beleth</strong> - And another one from Reroll Targets,
            Operator Five, Beleth is a Human Soul and has been long hailed as
            the second coming of Yuria with her great heals and plethora of
            Offensive buffs. She is primarily an Offensive buffer, however her
            frequent jumps to the frontline tend to get her killed sometimes.
          </li>
          <li>
            <strong>Onyx</strong> - Definitely the last one from Reroll Targets,
            CEO of Bestie Production and an otherworldly Alien, Onyx is a Beast
            Soul that is the only unit in-game besides Yuria having Type Damage
            buffs, an extremely strong Offensive buff for Raid content with
            singular faction enemies. She also provides extremely strong heals,
            a few more Offensive buffs amongst other stuff. However, half of her
            kit disabled when played with A/D/C characters., thus putting limits
            on her usage with certain DPS and teams. This isn't a major
            restriction in Guild Raids however, as you can easily create Beast
            or Undead teams which do not use any A/D/C characters.
          </li>
          <li>
            <strong>Tasha</strong> - The Everstagram Model doubling as feared
            Assassin, Tasha is a Beast Soul that features high Single Target
            damage . Her gameplay consists of moving around the field and
            assassinating enemies before they can defend, she features an
            insanely fast rotation that launches a flurry of non-stop attacks
            against the enemy team, as a result be it 1v5 Raid Content or 5v5
            PvE Content, Tasha fears none with her great damage. As an icing on
            the cake, she even has built in Dispel to get rid of some pesky
            enemies gimmicks such as Daphne Immortality and makes enemies more
            susceptible to Critical hits along with decreasing their Physical
            resistance.
          </li>
          <li>
            <strong>Garnet</strong> - An Yandere from Underworld of Tabria,
            Garnet is an Undead Soul and served as De Facto the best Debuffer in
            the game for a long time. Presently while she no longer dominates
            the archetype, she is still one of the very best Debuffer's in-game.
            Garnet increases not only the team damage significantly by Defense
            Down and Damage Increase debuffs, but also team's survival through
            her teamwide increased Lifesteal and Attack Down debuffs. To top it
            off, she inflicts a good amount of Bleed through her kit, allowing
            her to enable bonus damage on various Bleed oriented characters such
            as Velanna, Ayame and Jacqueline. An all over good choice.
          </li>
          <li>
            <strong>Vivienne</strong> - The Witch of the End, Vivienne is a
            launch Fairy Soul, and might have you wondering, is she still
            relevant?! But yes she is, Vivienne is an evergreen character that
            is a strong Buffer-cum-DPS. Her main gimmick is insane Speed buffs
            for the entire team and a large Attack as well as Mana Regen buff
            for herself, allowing her to achieve insanely fast rotations (for
            reference, against some of the slow characters, she can spam her
            Main almost twice as fast!), this allows her to put some impressive
            numbers in very short period of time. She might no longer be at the
            top, but she will always find her way back into the Meta for most of
            the in-game content.
          </li>
          <li>
            <strong>Daphne</strong> - The Blacksmith from Countryside, Daphne is
            a soul that completely subverted the Tank meta with her arrival and
            we have never looked back. Instead of the traditional Tanking model
            of being HP sponges and racking up Resistances to make them last
            longer, Daphne has built in 10 second invulnerability once she
            receives fatal damage, after which she heals back to full HP once
            per fight. An insane skill set for a Tank to have as she essentially
            has twice the HP pool and a 10 second window where she is completely
            immune to everything but Dispel. You thought that was all? Nope,
            raising the stakes even more, Daphne stores all the damage she
            receives and unleashes it back on enemies with her Main Skill, often
            wiping out entire frontlines or even teams itself if clubbed
            together with it! While her mechanics allow her to work with low
            ascensions, she is certainly very worthy of Origin for Raids and
            other endgame content.
          </li>
        </ul>
        <p>
          Wew, that was a lot of text! But didn’t you say we only have 5 slots?
          This is 11 characters! And that's where your personal preferences come
          in.
        </p>
        <p>
          Ideally we would suggest to Wishlist at least 1 DPS (Hazel = Sigrid
          &gt; Tasha in priority), at least 1 Healer/Buffer (Wheri = Beleth &gt;
          Onyx / Vivienne), at least 1 Debuffer (Dominique &gt; Lilith &gt;
          Garnet) and for the rest 2 slots, you can either raise another DPS or
          invest in more universal supports from ones you did not pick earlier.
          Daphne is also an option if she is having issues staying alive in BF
          anymore.
        </p>
        <p>
          <strong>Example Wishlist</strong>
        </p>
        <p>Yuria - Hazel - Sigrid - Dominique - Wheri - Lilith/Onyx.</p>
        <p>
          For Rare Soul, you can either keep the slot free for everything goes
          or pick any particular faction to raise their souls quickly (more
          often than not{' '}
          <strong>Fairy &gt; Humans are the most used factions</strong> and thus
          need more fodder).
        </p>
        <p>
          That will be all for now, until we get more characters and or more
          updates to the system!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll & Wishlist Guide | Eversoul | Prydwen Institute"
    description="Guide detailing Reroll Process, its targets and what to select in Wishlist Banner in Eversoul!"
    game="eversoul"
  />
);
